import React, { useState } from "react"
import { Container, Icon, Menu, Sidebar } from "semantic-ui-react"

const NavBar = ({ header, leftItems, rightItems, children }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false)

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible)
  }

  const hideSidebar = () => {
    setSidebarVisible(false)
  }

  return (
    <>
      <Menu fixed="top" inverted color="blue">
        <Menu.Item onClick={toggleSidebar} className="mobile only">
          <Icon name="sidebar" />
        </Menu.Item>
        {header}
        <Menu.Menu position="left" className="mobile hidden">
          {leftItems}
        </Menu.Menu>
        <Menu.Menu position="right">{rightItems}</Menu.Menu>
      </Menu>

      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        content={leftItems}
        vertical
        visible={sidebarVisible}
      />
      <Sidebar.Pusher
        dimmed={sidebarVisible}
        onClick={hideSidebar}
        style={{ minHeight: "100vh" }}
      >
        <div className="nav-bar-top-children">
          <Container>{children}</Container>
        </div>
      </Sidebar.Pusher>
    </>
  )
}

export default NavBar
