import gql from "graphql-tag"

export const USUARIO_HASH_QUERY = gql`
  query hash {
    hash {
      timestamp
      salt
      error
    }
  }
`

export const USUARIO_LOGADO_QUERY = gql`
  query current($token: String!) {
    current(token: $token) {
      username
      sessionId
      token
      error
    }
  }
`
