import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import moment from "moment"
import numeral from "numeral"
import "numeral/locales/pt-br"

// locale for dates and numbers
moment.locale("pt-br")
numeral.locale("pt-br")

const NAMESPACES = [
  "auth",
  "common",
  "error",
  "finance",
  "product",
  "person",
  "pricelist",
  "saleOrder",
  "paymentTerm",
  "settings",
]

/**
 * Fetch translation file(s).
 * @function getTranslation
 * @return {object} Fetched translation files.
 */

export async function getTranslation() {
  return {
    "pt-BR": NAMESPACES.reduce((acc, ns) => {
      acc[ns] = require(`../lang/pt-BR/${ns}.json`)
      return acc
    }, {}),
  }
}

/**
 * Initialize a i18next instance.
 * @function startI18n
 * @param {object} files - Translation files.
 */
export const startI18n = (files) => {
  /*
  const optionsV9 = {
    lng: "pt-BR",
    fallbackLng: "pt-BR",
    resources: files,
    ns: ["common"],
    defaultNS: "common",
    debug: false
  }
  */

  const options = {
    lng: "pt-BR",
    fallbackLng: "pt-BR",
    resources: files,
    ns: ["common"],
    defaultNS: "common",
    debug: false,
    react: {
      useSuspense: false,
    },
  }

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(options)

  return i18n
}
