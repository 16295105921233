import React, { useContext } from "react"
import { useQuery } from "@apollo/client"
import { Loader } from "semantic-ui-react"
import _ from "lodash"
import { PERMISSAO_INIT_QUERY } from "./gql/queries"

export const CLASSE_PEDIDO = "brax.erp.balcao.views.televendas.Pedido"
export const CLASSE_PESSOA = "brax.erp.views.pessoa.Pessoa"
export const CLASSE_RESUMO_VENDA =
  "brax.erp.balcao.views.relatorios.ResumoVendas"

export const PermissaoContext = React.createContext({})

export const PermissaoContextProvider = ({ children }) => {
  let permissoes = []
  const { data, error, loading } = useQuery(PERMISSAO_INIT_QUERY)

  if (data) {
    permissoes = data.permissoes
  }

  return (
    <PermissaoContext.Provider value={{ permissoes }}>
      {loading ? (
        <Loader loading="true" />
      ) : error ? (
        <div>{error.message}</div>
      ) : (
        children
      )}
    </PermissaoContext.Provider>
  )
}

export const usePermissao = (chave, classe) => {
  const { permissoes } = useContext(PermissaoContext)
  let permissao = _.find(permissoes, item => {
    return item.chave == chave && item.classe == classe
  })
  if (permissao) {
    return true
  }
  return false
}
