import React from "react"
import { Dropdown, Menu, Icon, Image, Container } from "semantic-ui-react"
import { NotificationContainer } from "react-notifications"
import Head from "next/head"
import Link from "next/link"
import moment from "moment"
import numeral from "numeral"
import "numeral/locales/pt-br"
import NavBar from "../common/NavBar"
import UserButton from "../common/auth/UserButton"
import redirect from "../../lib/redirect"
import LoginBox from "./auth/LoginBox"
import { useAuth } from "../../lib/auth"
import { useRouter } from "next/router"
import "react-dates/initialize"
import "tailwindcss/tailwind.css"
import "react-dates/lib/css/_datepicker.css"
import ConfirmContextProvider from "./hooks/comfirm/ConfirmContextProvider"
import ConfirmDialog from "./ConfirmDialog"

// locale for dates and numbers
moment.locale("pt-br")
numeral.locale("pt-br")

const MenuItem = ({ icon, text, ...rest }) => (
  <Dropdown
    icon={null}
    trigger={
      <>
        <Icon name={icon} />
        {text}
      </>
    }
    {...rest}
  />
)

const TopMenu = ({ className, route }) => (
  <>
    <Link href="/pedido">
      <a className={className}>
        <Menu.Item className="fluid-v" link active={route == "/home"}>
          <Icon name="cart" />
          Pedidos
        </Menu.Item>
      </a>
    </Link>
  </>
)

const Page = ({ children, ...rest }) => {
  const auth = useAuth()
  const { pathname } = useRouter()
  const { isLoggedIn } = useAuth()

  if (pathname === "/login" || !isLoggedIn) {
    return (
      <>
        <LoginBox />
      </>
    )
  }
  const navigate = (newRoute) => {
    // TODO store.hideSidebar()
    redirect({}, newRoute)
  }

  return (
    <>
      <NavBar
        header={
          <Menu.Item
            key="dashboard"
            onClick={() => navigate("/")}
            style={{ backgroundColor: "white" }}
          >
            <Image
              src="/static/images/logo-horiz.png"
              alt="braxERP"
              layout="fill"
            />
          </Menu.Item>
        }
        leftItems={[
          <Menu.Item
            key="saleOrder"
            icon="file text"
            onClick={() => navigate("/pedido")}
            content="Pedido de Vendas"
          />,

          <Menu.Item
            key="customer"
            icon="users"
            onClick={() => navigate("/cliente")}
            content="Cadastro de Clientes"
          />,

          <MenuItem key="results" item icon="line chart" text="Resultados">
            <Dropdown.Menu>
              <Dropdown.Item
                text="Resumo de Vendas"
                onClick={() => navigate("/relatorios/resumovenda")}
              />
            </Dropdown.Menu>
          </MenuItem>,
        ]}
        rightItems={[
          /*<Dropdown key="notifications" item icon="bell">
              <Dropdown.Menu>
                <Dropdown.Header content="Notificações" />
                <Dropdown.Item text="Nenhuma notificação nova" />
              </Dropdown.Menu>
            </Dropdown>,

            <Dropdown key="settings" item icon="cogs">
              <Dropdown.Menu>
                <Dropdown.Header content="Configurações" />

                <Dropdown.Item icon="building" text="Empresa" />
                <Dropdown.Item
                  key="preferences"
                  text="Configurações"
                  icon="edit outline"
                />
              </Dropdown.Menu>
            </Dropdown>,*/
          <Menu.Item
            key="store"
            icon="building"
            content={auth?.loja?.apelido || "Nome da loja"}
          />,
          <UserButton key="user" />,
          /*<Dropdown key="help" item icon="question circle">
                <Dropdown.Menu>
                  <Dropdown.Header content="Suporte" />

                  <Dropdown.Item text="Nenhum conteúdo" />
                </Dropdown.Menu>
              </Dropdown>*/
        ]}
      >
        <div className="main-content">{children}</div>
      </NavBar>
    </>
  )
}

const Layout = (props) => {
  const { children } = props

  return (
    <Page>
      <ConfirmContextProvider>
        {children}
        <ConfirmDialog />
      </ConfirmContextProvider>
      <NotificationContainer />
    </Page>
  )
}

/*Layout.getInitialProps = async ctx => {
  const { req } = ctx
  let userAgent = null
  if (req && req.headers) {
    userAgent = req.headers["user-agent"]
  }
  const md = new MobileDetect(userAgent)
  const isMobileFromSSR = !!md.mobile()

  return {
    isMobileFromSSR,
    deviceInfo: {
      mobile: md.mobile(),
      tablet: md.tablet(),
      os: md.os(),
      userAgent: md.userAgent()
    }
  }
}*/

export default Layout
