import { useContext, useMemo } from "react"
import { ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/link-context"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"
import Router, { useRouter } from "next/router"
import cookie from "cookie"
import { parseCookies } from "./auth"

let apolloClient

function createApolloClient(loja, pathname) {
  console.log("createApolloClient.pathname", pathname)
  const httpLink = createUploadLink({
    uri: loja?.graphqlUri,
    credentials: "same-origin",
  })

  const errorLink = onError((errorProps) => {
    console.log("errorProps", errorProps)
    console.log("pathname", pathname)
    const { graphQLErrors, networkError } = errorProps
    if (graphQLErrors) {
      let sessionMessage = null

      graphQLErrors.map(({ message, locations, path }) => {
        if (
          message.includes("JWTExpired") ||
          message.includes(
            "Missing 'Authorization' or 'Cookie' header in JWT authentication mode"
          )
        ) {
          sessionMessage = "Sessão experida"
        } else if (
          message.includes("no such type exists in the schema") ||
          message.includes("not found in type: 'query_root'")
        ) {
          sessionMessage = "Sessão desconectada"
        } else if (message.includes("JWTIssuedAtFuture")) {
          sessionMessage =
            "Token gerado com horário futuro. Para evitar o problema o horário do servidor deve ser corrigido."
        } else {
          throw new Error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        }
      })

      if (sessionMessage) {
        document.cookie = cookie.serialize("token", "", { maxAge: -1 })
        document.cookie = cookie.serialize("username", "", {
          maxAge: -1,
        })

        if (pathname !== "/login") {
          Router.push({
            pathname: "/login",
            query: { error: sessionMessage },
          })
        }
      }
    }

    if (networkError) throw new Error(`[Network error]: ${networkError}`)
  })

  const authLink = setContext((_, { headers }) => {
    const token = parseCookies().token
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      }
    } else {
      return { headers }
    }
  })

  return new ApolloClient({
    ssrMode: false,
    connectToDevTools: true,
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {},
        },
      },
    }),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
  })
}

export function initializeApollo(loja, initialState = null, pathname) {
  //const _apolloClient = apolloClient ?? createApolloClient(endpoint)
  const _apolloClient = createApolloClient(loja, pathname)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(loja, initialState, pathname) {
  const store = useMemo(
    () => initializeApollo(loja, initialState, pathname),
    [loja, initialState, pathname]
  )

  return store
}
