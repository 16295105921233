import { Button, Container, Modal } from "semantic-ui-react"
import useConfirm from "./hooks/comfirm/useConfirm"
import ModalPanel from "./ModalPanel"

const ConfirmDialog = () => {
  const { onConfirm, onCancel, confirmState } = useConfirm()
  return (
    <Modal open={confirmState.show} size="small">
      <ModalPanel title="Confirmação" onClose={onCancel}>
        <div className="py-5">{confirmState?.text && confirmState.text}</div>
        <Container textAlign="right">
          <Button type="button" secondary onClick={onCancel} content="Cancelar" />
          <Button type="button" positive onClick={onConfirm} content="Confirmar" />
        </Container>
      </ModalPanel>
    </Modal>
  )
}

export default ConfirmDialog
