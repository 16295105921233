import gql from "graphql-tag"

export const PERMISSAO_INIT_QUERY = gql`
  query permissao_init {
    permissoes: permissao {
      usuario_id
      classe
      chave
      descricao
    }
  }
`
