import gql from "graphql-tag"

export const LOGIN_MUTATION = gql`
  mutation login(
    $timestamp: String!
    $salt: String!
    $user: String!
    $hash: String!
  ) {
    login(timestamp: $timestamp, salt: $salt, user: $user, hash: $hash) {
      sessionId
      token
      username
      error
    }
  }
`

export const LOGOUT_MUTATION = gql`
  mutation logout($token: String!) {
    logout(token: $token) {
      status
      error
    }
  }
`
