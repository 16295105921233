import React, { useReducer } from "react"
import ConfirmContext, { SHOW_CONFIRM, HIDE_CONFIRM } from "./ConfirmContext"

const initialState = {
  show: false,
  text: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        show: true,
        text: action.payload.text,
      }
    case HIDE_CONFIRM:
      return initialState
    default:
      return initialState
  }
}

const ConfirmContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <ConfirmContext.Provider value={[state, dispatch]}>{children}</ConfirmContext.Provider>
}

export default ConfirmContextProvider
