import { Button, Segment } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import Header from "./Header"

const ModalPanel = ({ title, onClose, children }) => {
  const { t } = useTranslation()
  return (
    <Segment>
      <Header title={title}>
        <Button floated="right" icon="close" onClick={() => onClose()} />
      </Header>
      {children}
    </Segment>
  )
}

export default ModalPanel
