// @flow
import React, { useState } from "react"
import {
  Form,
  Segment,
  Container,
  Grid,
  Header,
  Button,
  Message,
  Input,
  Loader,
  Image,
  Select,
  Icon,
} from "semantic-ui-react"
import CryptoJS from "crypto-js"
import { useApolloClient, useQuery, useMutation } from "@apollo/client"
import getConfig from "next/config"
import { USUARIO_HASH_QUERY } from "./gql/queries"
import { LOGIN_MUTATION } from "./gql/mutations"
import cookie from "cookie"
import redirect from "../../../lib/redirect"
import { useAuth } from "../../../lib/auth"
import { useRouter } from "next/router"
import Link from "next/link"

const LoginBoxComponent = (props) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [login] = useMutation(LOGIN_MUTATION)
  const client = useApolloClient()
  const auth = useAuth()

  const { lojaSelecionada, lojas } = props

  const lojaOptions = lojas.map((loja) => ({
    key: loja.id,
    text: loja.apelido,
    value: loja,
  }))

  /*
  const hashProps = {
    data: {
      hash: {
        timestamp: "1600392606541",
        salt:
          "L;IoLlo;[UlK#;GgFa-q2BWwN3kPG+#Qh0P/V`avZl[=|R}pO~qTH/_{U-yH=7Fn'"
      }
    }
  }
*/
  const hashProps = useQuery(USUARIO_HASH_QUERY)

  const handleSignIn = () => {
    setError(null)
    if (!username || !password) {
      setError("Preencha o login e a senha para continuar!")
    } else if (!hashProps.data?.hash) {
      setError(
        "Não foi possível criar uma nova sessão. Por favor, recarregue a página e tente novamente!"
      )
    } else {
      const { salt, timestamp } = hashProps.data.hash
      setLoading(true)

      let key1 = CryptoJS.MD5(password).toString()
      let key2 = CryptoJS.HmacSHA1(salt, key1).toString()
      let hash = CryptoJS.HmacSHA1(timestamp, key2).toString()

      const inputData = {
        timestamp,
        salt: "",
        user: username,
        hash,
      }

      login({ variables: inputData, errorPolicy: "all" }).then(
        async ({ data, errors }) => {
          const errorType = data?.login?.error

          if (errorType) {
            let errorMessage = ""
            switch (errorType) {
              case "INVALID_CREDENTIAL":
                errorMessage = "As credenciais do usuário não são válidas"
                break
              case "BLOCKED_USER":
                errorMessage = "Usuário bloqueado"
                break
              case "BLOCKED_STORE_ACCESS":
                errorMessage =
                  "Você não tem permissão para acessar este estabelecimento"
                break
              case "INVALID_USER_OR_PASSWORD":
                errorMessage = "Login ou senha inválida"
                break
              case "LICENSE_LIMIT_REACHED":
                errorMessage = "O limite de licenças foi atingido"
                break
              default:
                errorMessage = "Ocorreu um erro não mapeado na autenticação"
                break
            }
            setError(errorMessage)
          } else {
            client.cache.reset().then(() => {
              if (data) {
                // Store the token in cookie
                document.cookie = cookie.serialize("token", data.login.token, {
                  maxAge: 30 * 24 * 60 * 60, // 30 days
                })

                auth.setIsLoggedIn(Boolean(data.login.username))

                // Store the username in cookie
                document.cookie = cookie.serialize(
                  "username",
                  data.login.username,
                  {
                    maxAge: 30 * 24 * 60 * 60, // 30 days
                  }
                )

                auth.setUsername(data.login.username)

                redirect({}, "/")
              } else if (errors) {
                errors.forEach((error) => {
                  setError(error.message)
                })
              }
            })
          }
        }
      )

      setLoading(false)
    }
  }

  return (
    <Container>
      <Grid textAlign="center" padded>
        <Grid.Column mobile={16} tablet={10} computer={6}>
          <Image
            src="/static/images/logoERP.png"
            alt="braxERP"
            layout="fill"
            centered
          />
          <Header color="blue" as="h2">
            <Header.Content>Identifique-se</Header.Content>
          </Header>
          <Form
            onSubmit={handleSignIn}
            error={!!error}
            loading={hashProps.loading}
          >
            <Segment>
              <Form.Field>
                <Select
                  placeholder="Selecione a Loja"
                  options={lojaOptions}
                  onChange={(_, obj) => {
                    if (obj.value) {
                      const novaLoja = obj.value
                      document.cookie = cookie.serialize(
                        "loja",
                        JSON.stringify(novaLoja),
                        {
                          maxAge: 30 * 24 * 60 * 60, // 30 days
                        }
                      )
                      auth.setLoja(novaLoja)
                    }
                  }}
                  defaultValue={lojaSelecionada}
                />
              </Form.Field>

              <Input
                id="login"
                placeholder="Usuário"
                icon="user"
                iconPosition="left"
                autoFocus
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                style={{ marginBottom: 10 }}
              />

              <Input
                id="password"
                placeholder="Senha"
                type="password"
                icon="lock"
                iconPosition="left"
                validate="required"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                style={{ marginBottom: 10 }}
              />

              <Button fluid color="blue" size="large" type="submit">
                Entrar
              </Button>
              <Loader active={loading} />
            </Segment>

            <Message error>
              <Message.Header>
                Houve um problema com o login, verifique suas credenciais.
              </Message.Header>
              Erro: {error}
            </Message>
          </Form>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

const LoginBox = (props) => {
  const auth = useAuth()
  const { query } = useRouter()
  const lojas = []

  if (auth && auth.isLoggedIn && !query?.error) {
    return (
      <div className="flex flex-row justify-around items-center h-screen text-center bg-blue-500">
        <div className=" border-2 rounded-lg shadow-md shadow-blue-300 p-10 bg-white">
          <span className="text-gray-700 text-2xl font-extrabold">
            Usuário autenticado
          </span>
          <Link href="/" passHref>
            <div className="flex flex-row items-center gap-2 border-2 border-gray-500 rounded-md p-2 bg-gray-100 mt-4 hover:bg-gray-300 cursor-pointer">
              <span>
                <Icon name="home" />
              </span>
              <span className="text-lg">Voltar para a Home</span>
            </div>
          </Link>
        </div>
      </div>
    )
  }

  let lojaSelecionada = auth.loja

  console.log(
    "App.Config.LojaApelido:",
    getConfig().publicRuntimeConfig?.NEXT_PUBLIC_LOJA_APELIDO
  )

  const graphqlUrl = getConfig()?.publicRuntimeConfig?.NEXT_PUBLIC_GRAPHQL_URL

  const graphqlUris = graphqlUrl ? graphqlUrl?.split(";") : []
  const ids = graphqlUrl
    ? getConfig().publicRuntimeConfig?.NEXT_PUBLIC_LOJA_ID?.split(";")
    : []
  const apelidos = graphqlUrl
    ? getConfig().publicRuntimeConfig?.NEXT_PUBLIC_LOJA_APELIDO?.split(";")
    : []

  console.log("graphqlUris", graphqlUris)
  console.log("ids", ids)
  console.log("apelidos", apelidos)

  if (ids.length > 0) {
    for (let i = 0; i < ids.length; i++) {
      const lojaId = ids[i]
      const dadosLoja = {
        id: lojaId,
        apelido: apelidos[i] || "Nome da Loja",
        graphqlUri: graphqlUris[i],
      }
      lojas.push(dadosLoja)

      if (!lojaSelecionada) {
        lojaSelecionada = dadosLoja
      }
    }
  } else {
    lojas.push({
      id: 0,
      apelido: "",
      graphqlUri: null,
    })
  }

  if (!lojaSelecionada) {
    lojaSelecionada = {
      id: 0,
      apelido: "Nome da Loja",
    }
  }

  console.log("lojas", lojas)
  return (
    <>
      {query.error && (
        <div className="flex flex-row justify-center m-5">
          <Message error>{query.error}</Message>
        </div>
      )}
      <LoginBoxComponent
        lojaSelecionada={lojaSelecionada}
        lojas={lojas}
        {...props}
      />
    </>
  )
}

export default LoginBox
