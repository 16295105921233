import cookie from "cookie"
import jwt from "jsonwebtoken"
import React, { useEffect } from "react"

export const parseCookies = (req, options = {}) => {
  // return cookie.parse(req ? req.headers.cookie || "" : document.cookie, options)
  if (req) {
    if (req.headers) {
      return cookie.parse(req.headers.cookie || "", options)
    }
  } else {
    if (document) {
      return cookie.parse(document.cookie, options)
    }
  }

  return null
}

const Auth = React.createContext({ isLoggedIn: false })

export const AuthProvider = ({ children, loja, setLoja }) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState()
  const [username, setUsername] = React.useState("OFF-LINE")

  useEffect(() => {
    setIsLoggedIn(Boolean(cookie.parse(document.cookie).token))
    setUsername(cookie.parse(document.cookie).username)
  }, [])

  return (
    <Auth.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        username,
        setUsername,
        loja,
        setLoja,
      }}
    >
      {children}
    </Auth.Provider>
  )
}

export const getUserId = () => {
  let userId = 0
  let token = cookie.parse(document.cookie).token
  if (token) {
    let decodedToken = jwt.decode(token)
    if (decodedToken && decodedToken.userId) {
      userId = decodedToken.userId
    }
  }
  return userId
}

export const useAuth = () => React.useContext(Auth)

/*
const login = async (client, store, mutation, username, password) => {
  const response = await api.signIn(username, password)

  let { token, loginError } = response

  if (loginError) {
    throw loginError
  }

  if (token) {
    client.cache.reset().then(() => {
      // Store the token in cookie
      document.cookie = cookie.serialize("token", token, {
        maxAge: 30 * 24 * 60 * 60 // 30 days
      })

      store.login(username)
      redirect({}, "/")
    })
  }
}
const logout = (client, store) => {
  // Force a reload of all the current queries now that the user is
  // logged in, so we don't accidentally leave any state around.
  client.cache.reset().then(() => {
    // Expire the cookie immediately
    document.cookie = cookie.serialize("token", "", { maxAge: -1 })

    store.logout()

    // Redirect to a more useful page when signed out
    redirect({}, "/login")
  })
}

const checkLoggedIn = async req => {
  let user = {
    loggedIn: false,
    name: ""
  }

  let token = parseCookies(req).token

  if (token) {
    await api
      .getUsuarioLogado(token)
      .then(response => {
        if (response && response.data) {
          user = {
            loggedIn: true,
            name: response.data.username
          }
        }
      })
      .catch(erro => {
        // Fail gracefully
        console.log("Usuario Logado[ERRO]:", erro)
      })
  }

  return { user }
}
*/
