import React from "react"
import cookie from "cookie"
import { useMutation } from "@apollo/client"
import { Dropdown, Icon, Menu } from "semantic-ui-react"
import redirect from "../../../lib/redirect"
import { useAuth } from "../../../lib/auth"
import { LOGOUT_MUTATION } from "./gql/mutations"

export default function UserButton() {
  const [logoutMutation] = useMutation(LOGOUT_MUTATION)
  const authProps = useAuth()
  const { isLoggedIn, setIsLoggedIn, username, setUsername } = authProps

  const handleLogout = () => {
    try {
      const cookieData = cookie.parse(document.cookie)
      const token = cookieData?.token
      logoutMutation({
        variables: {
          token,
        },
      }).then(() => {
        // Expire the cookie immediately
        document.cookie = cookie.serialize("token", "", {
          maxAge: -1,
        })

        document.cookie = cookie.serialize("username", "", {
          maxAge: -1,
        })

        setIsLoggedIn(false)
        setUsername("OFF-LINE")
        // Redirect to a more useful page when signed out
        redirect({}, "/login")
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dropdown
      item
      icon={null}
      trigger={
        <>
          <Icon name="user" />
          <div className="hidden sm:block">{username}</div>
        </>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Header className="block sm:hidden">
          {username}
        </Dropdown.Header>
        <Dropdown.Item icon="sign out" onClick={handleLogout} text="Sair" />
      </Dropdown.Menu>
    </Dropdown>
  )
}
