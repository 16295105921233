// @flow
import React, { useEffect, useState } from "react"
import { ApolloProvider } from "@apollo/client"
import Layout from "../components/common/Layout"
import { I18nextProvider } from "react-i18next"
import cookie from "cookie"
import getConfig from "next/config"
import { useApollo } from "../lib/apolloClient"
import { getTranslation, startI18n } from "../lib/i18n"
import { AuthProvider } from "../lib/auth"
import { PermissaoContextProvider } from "../components/common/PermissaoContext"
import Head from "next/head"
import "../public/static/css/main.css"
import "../public/static/css/responsive.css"
import "../public/static/css/nprogress.css"
import "../public/static/css/notifications.css"
import "../public/static/css/image-gallery.css"
import { useRouter } from "next/router"

export async function getServerSideProps(context) {
  return {
    props: {
      graphqlUris,
      ids,
      apelidos,
    }, // will be passed to the page component as props
  }
}

const App = ({ Component, pageProps, config, pathname, ...rest }) => {
  const [loja, setLoja] = useState()
  //const initialApolloState = pageProps.initialApolloState
  const apolloClient = useApollo(loja, pageProps.initialApolloState, pathname)
  const [translation, setTranslation] = useState()
  const i18n = startI18n(translation)

  useEffect(() => {
    async function loadTranslation() {
      setTranslation(await getTranslation())
    }

    loadTranslation()

    const lojaCached = cookie.parse(document.cookie).loja

    if (lojaCached) {
      setLoja(JSON.parse(lojaCached))
    } else if (config.ids.length > 0) {
      setLoja({
        id: config.ids[0],
        apelido: config.apelidos[0],
        graphqlUri: config.graphqlUris[0],
      })
    }
  }, [config])

  const meta = {
    title: "braxERP - Web",
    image: "/static/images/logoERP.png",
    description: "Software de Gestão Comercial",
  }

  return (
    <>
      <Head>
        <title>{`${meta.title} - ${meta.description}`}</title>
        <meta name="title" content={meta.title} />
        <meta
          property="og:description"
          name="description"
          content={meta.description}
        />
        <meta property="og:title" content={meta.title} />
        <meta property="og:site_name" content="braxERP" />

        {meta.image && <meta property="og:image" content={meta.image} />}
        <meta
          name="Keywords"
          content="Brax ERP, Software, Gerenciamento, Loja"
        />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="user-scalable=no,initial-scale=1,width=device-width"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#2185d0" />

        {/* TODO: Remover tag abaixo após converter a API REST do ERP para HTTPS --
    
    <meta
      httpEquiv="Content-Security-Policy"
      content="upgrade-insecure-requests"
    />*/}
      </Head>
      <ApolloProvider client={apolloClient}>
        <I18nextProvider i18n={i18n}>
          <AuthProvider loja={loja} setLoja={setLoja}>
            <Layout>
              <PermissaoContextProvider>
                <Component {...pageProps} />
              </PermissaoContextProvider>
            </Layout>
          </AuthProvider>
        </I18nextProvider>
      </ApolloProvider>
    </>
  )
}

const AppContainer = (props) => {
  const { pathname } = useRouter()
  const graphqlUrl = getConfig()?.publicRuntimeConfig?.NEXT_PUBLIC_GRAPHQL_URL

  const graphqlUris = graphqlUrl
    ? getConfig().publicRuntimeConfig?.NEXT_PUBLIC_GRAPHQL_URL?.split(";")
    : []
  const ids = graphqlUrl
    ? getConfig().publicRuntimeConfig?.NEXT_PUBLIC_LOJA_ID?.split(";")
    : []
  const apelidos = graphqlUrl
    ? getConfig().publicRuntimeConfig?.NEXT_PUBLIC_LOJA_APELIDO?.split(";")
    : []

  const config = {
    graphqlUris,
    ids,
    apelidos,
  }

  return <App {...props} config={config} pathname={pathname} />
}

export default AppContainer
